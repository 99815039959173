:root {
  --primary-color: "#007bff";
  --secondary-color: "#0056b3";
}

body {
  width: 100%;
  max-width: 420px;
  justify-content: center;
  display: flex;
  margin: 0 auto;
}

.headerImg {
  width: 100%;
  height: auto;
}

.discount-box,
.delivery-box {
  background-color: white;
  padding: 20px;
  margin: 10px 0;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 90%;
}

.discount-box h2,
.delivery-box h2 {
  font-size: 24px;
  margin: 0 0 10px;
}

.discount-box p,
.delivery-box p {
  font-size: 14px;
  color: #777;
  margin: 10px 0;
}

.discount-box .price,
.delivery-box .info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.discount-box .price span,
.delivery-box .info span {
  font-size: 24px;
  margin: 0 10px;
}

.discount-box .price .old-price {
  text-decoration: line-through;
  color: #999;
}

.discount-box .price .new-price {
  color: var(--primary-color);
}

.discount-box button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
}

.discount-box span{
  color: var(--primary-color);
}

.discount-box button:hover {
  background-color: var(--secondary-color);
}

.delivery-box {
  text-align: left;
}

.delivery-box .info {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.delivery-box .info:last-child {
  border-bottom: none;
}

.delivery-box .info .Image {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  margin-right: 10px;
  border-radius: 100%;
  color: white;
  background-color: var(--secondary-color);
  text-align: center;
  line-height: 48px;
}

.delivery-box .info .Image .Icon {
  width: 20px;
  height: 20px;
}

.delivery-box .info div {
  flex: 1;
}

.delivery-box .info strong {
  display: block;
  color: #333;
  margin-bottom: 5px;
  font-size: 16px;
}

.delivery-box .info p {
  margin: 0;
  color: #777;
  font-size: 14px;
}

.tech-block {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow: hidden;
}

.tech-header {
  background-color: #f7f7f7;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.tech-block img {
  width: 100%;
  margin: 10px 0зч;
}

.tech-description {
  padding: 20px;
}

.tech-description p {
  margin: 10px 0;
}

.form-group {
  margin: 6px 0;
}
.form-group input {
  width: 90%;
  height: 24px;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
}

.form-group input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.contact-box {
  background-color: white;
  padding: 20px;
  margin: 10px 0;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  text-align: center;
}
.contact-box p {
  margin: 5px 0;
  color: #333;
  font-size: 14px;
}
.contact-box strong {
  display: block;
  margin-top: 10px;
  color: var(--primary-color);
}
.contact-box a {
  color: var(--primary-color);
  text-decoration: none;
}
.contact-box a:hover {
  text-decoration: underline;
}